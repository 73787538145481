<template>
    <div>
        <section class="encabezado encuesta-medicina row-start">

            <h2>Encuesta</h2>

        </section>
        <section class="contenedor-encuesta row-between" id="encuesta">

            <h2>Dirigida a los alumni Ufv de medicina</h2>

            <legend>Datos personales</legend>


            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="Nombre" v-model="data.name"
                                :class="classes" >
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="surnames" placeholder="Apellidos"
                                v-model="data.surnames" :class="classes" >
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="dni" placeholder="DNI" :class="classes"
                                 v-model="data.dni">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="email" placeholder="Correo electrónico"
                                :class="classes" v-model="data.email">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <legend>Dirección postal</legend>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="address" placeholder="Dirección completa"
                                :class="classes"  v-model="data.address">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="cp" placeholder="Código postal" :class="classes"
                                 v-model="data.cp">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="city" placeholder="Población" :class="classes"
                                 v-model="data.city">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="province" placeholder="Provincia"
                                :class="classes"  v-model="data.province">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <legend>¿Estás haciendo la residencia a día de hoy?</legend>

                    <div class="contenedor-input row-start fww">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="radio" value="0" name="hospital_status" id="si" v-model="data.hospital_status"
                                :class="classes" >
                            <label for="si">Sí</label>
                            <input type="radio" value="1" name="hospital_status" id="no-mir"
                                v-model="data.hospital_status" :class="classes" >
                            <label for="no-mir">No, repito MIR</label>
                            <input type="radio" value="2" name="hospital_status" id="no-trabajando"
                                v-model="data.hospital_status" :class="classes" >
                            <label for="no-trabajando">No, estoy trabajando</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <template v-if="data.hospital_status == '0'">

                        <legend>Datos de la residencia</legend>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="hospital_especiality"
                                    placeholder="Especialidad" :class="classes" 
                                    v-model="data.hospital_especiality">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="hospital_name" placeholder="Hospital"
                                    :class="classes"  v-model="data.hospital_name">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="hospital_city" placeholder="Ciudad"
                                    :class="classes"  v-model="data.hospital_city">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <select autocomplete="off" name="hospital_year" :class="classes" 
                                    v-model="data.hospital_year">
                                    <option value="">Año de residencia</option>
                                    <option value="1">Primer año</option>
                                    <option value="2">Segundo año</option>
                                    <option value="3">Tercer año</option>
                                    <option value="4">Cuarto año</option>
                                    <option value="5">Quinto año</option>
                                </select>

                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>

                    </template>

                    <template v-if="data.hospital_status == '2'">
                        <div class="contenedor-input">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <textarea name="hospital_work" :class="classes" 
                                    v-model="data.hospital_work" autocomplete="off"
                                    placeholder="Cuéntanos qué estás haciendo (oposiciones a inspector médico, reconocimientos médicos, industria farmacéutica, médico de estética, etc...)"></textarea>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>
                    </template>

                    <div class="contenedor-input -check">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="checkbox" id="id" :class="classes" 
                                v-model="data.politica" name="politica">
                            <label for="id">He leído y acepto la <a href="">Política de privacidad</a></label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <a @click="validate" class="boton-azul">Enviar</a>
                </form>

            </ValidationObserver>


            <div class="form-popup-encuesta" :show="!!error">
                <div class="popup" v-if="error">
                    <h2>{{errores[error].titulo}}</h2>
                    <p>{{errores[error].cuerpo}}</p>
                    <a @click="reload" class="boton-azul">Aceptar</a>
                </div>
            </div>

        </section>
    </div>
</template>


<script>

    import axios from 'axios';

    export default {
        name: 'encuesta-medicina',
        data: () => ({
            data: {
                name: '',
                surnames: '',
                dni: '',
                email: '',
                address: '',
                cp: '',
                city: '',
                province: '',
                hospital_especiality: '',
                hospital_name: '',
                hospital_city: '',
                hospital_year: '',
                hospital_work: '',
                hospital_status: '',
                politica: false
            },
            errores: {
                'send': {
                    titulo: '¡Muchas gracias!',
                    cuerpo: "Hemos recibido tus datos"
                }
            },
            error: null,
            clicked: false
        }),
        methods: {
            reset() {
                this.errors.clear();
                this.clicked = false;
                this.data = {
                    name: '',
                    surnames: '',
                    dni: '',
                    email: '',
                    address: '',
                    cp: '',
                    city: '',
                    province: '',
                    hospital_especiality: '',
                    hospital_name: '',
                    hospital_city: '',
                    hospital_year: '',
                    hospital_work: '',
                    hospital_status: '',
                    politica: false
                }
            },
            reload() {
                location.reload();
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        axios.post('https://cuestionarios.alumni-ufv.es/api/contacto/medico', this.data).then(response => {
                            if (response.data.status) {
                                this.error = 'send';
                                this.reset();
                            }
                        })

                    }
                });
            },
        }
    }
</script>